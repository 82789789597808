

const axes = (wght, wdth) => `font-variation-settings: "wght" ${wght}, "wdth"${wdth};`;

const theme = {
    background: '#f0e5ef',
    disabled: '#c2c2c2',
    text: '#000',
    main: '#EC3FB5',
    border: '#ddd',
    hover: '#ffebf8',
    offset: '#ffffff',
    font: {
        light: {
            normal: axes(300, 500),
            semiCondensed: axes(300, 300),
            condensed: axes(300, 100)
        },
        regular: {
            normal: axes(400, 500),
            semiCondensed: axes(400, 300),
            condensed: axes(400, 100)
        },
        bold: {
            normal: axes(600, 500),
            semiCondensed: axes(600, 300),
            condensed: axes(600, 100)
        },
        black: {
            normal: axes(900, 500),
            semiCondensed: axes(900, 300),
            condensed: axes(900, 100)
        },
    }
}

export default theme;