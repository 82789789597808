import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { gamesContext } from '../contextes/gamesContext';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from '../layout/Button';
import CheckButton from '../layout/CheckButton';
// eslint-disable-next-line no-unused-vars
import Code from './Code';


const GameType = styled.div`
    &:not(:last-child) {
        margin-bottom:1.2em;
    }
    h4 {
        ${props => props.theme.font.bold.semiCondensed};
        font-size: 1em;
        .warn {
            ${props => props.theme.font.bold.condensed};
            color: ${props => props.theme.main};
            animation: blink 700ms linear alternate infinite;
            opacity: 0;
            @keyframes blink {
                0% {
                } 
                100% {
                    opacity: 1
                }
            }
        }
    }
`;

const H3 = styled.h3`
    display: flex;
    margin:0;
    align-items:center;
    &.clickable {
        cursor: pointer;
        user-select: none;
    }
    span {
        flex-grow:1;
    }
    button.angle {
        color: ${props => props.theme.main};
        line-height: 1;
        background: none;
        border: none;
        i {
            font-size:1.5em;
        }
    }
`;

export default function GameSettings({ hiddenValues, setHiddenValues, filteredGames, hideToggle }) {
    // eslint-disable-next-line no-unused-vars
    const [games, setGames, gameTypes] = useContext(gamesContext);

    const [filterDropdown, setFilterDropdown] = useState(false);

    function toggleDropdown(e) {
        setFilterDropdown(!filterDropdown);
    }

    useLocalStorage('filter-dropdown', filterDropdown, setFilterDropdown);


    function toggleValue(gameType, value) {
        const currentValues = hiddenValues?.[gameType.id] || [];

        const newValues = currentValues.includes(value) ? currentValues.filter(v => v !== value) : [...currentValues, value];

        setHiddenValues({
            ...hiddenValues,
            [gameType.id]: newValues
        })
    }
    function clear() {
        setHiddenValues({});
    }
    function selectAll() {
        const allValues = gameTypes.reduce((result, gameType, index, allTypes) => {
            // console.log('gameType', gameType);
            // console.log('result', result);
            // console.log('index', index);
            // console.log('allTypes', allTypes);
            result[gameType.id] = gameType.values;
            return result;
        }, {});
        // console.log('allValues', allValues);
        setHiddenValues(allValues);
    }

    function toggleAllOfType(gameType) {
        console.log('gameType', gameType);
        const allValues = gameType.values;
        console.log('allValues', allValues);

        setHiddenValues({
            ...hiddenValues,
            [gameType.id]: typeHasHiddenValue(gameType) ? [] : allValues
        })
    }

    function typeHasHiddenValue(gameType) {
        return hiddenValues?.[gameType.id]?.length;
    }
    function allTypeValuesAreHidden(gameType) {
        return hiddenValues?.[gameType.id]?.length === gameTypes.find(gt => gt.id === gameType.id).values.length;
    }

    return (
        <div>
            <H3 onClick={toggleDropdown} className={!hideToggle ? 'clickable' : ''}>
                <span><i className="fas fa-filter"></i> סינון משחקים לפי:</span>
                {!hideToggle && <button className="angle"><i className={`fas fa-angle-${filterDropdown ? 'up' : 'down'}`}></i></button>}
            </H3>
            {(filterDropdown || hideToggle) && <>
                <hr />
                <div style={{ marginBottom: '1em' }}>
                    {(Object.keys(hiddenValues).length) !== 0 ?
                        <Button as="button" className=" wide small" onClick={(e) => { e.preventDefault(); clear(); }}> <i className="fas fa-plus"></i> סמן הכל</Button>
                        :
                        <Button as="button" className=" wide small" onClick={(e) => { e.preventDefault(); selectAll(); }}> <i className="fas fa-minus"></i> בטל סימון בהכל</Button>
                    }
                </div>
                {gameTypes.map((gameType, key) =>
                    <GameType key={key}>
                        <h4>{gameType.name} {allTypeValuesAreHidden(gameType) && <span className="warn">(יש לסמן אחד לפחות)</span>}</h4>

                        <CheckButton
                            checked={!typeHasHiddenValue(gameType)}
                            onClick={() => toggleAllOfType(gameType)}>הכל</CheckButton>
                        {(gameType.values).map((value, key) =>
                            <span key={key}>
                                {/* {console.log('hiddenValues?.[gameType.id]', hiddenValues?.[gameType.id])} */}
                                <CheckButton checked={!hiddenValues?.[gameType.id]?.includes(value)} onClick={() => toggleValue(gameType, value)}>{value ? value : 'לא מקוטלג'}</CheckButton>
                                {/* {gameSettings?.[gameType.id]?.[type] === undefined ? 'כן' : gameSettings?.[gameType.id]?.[type] ? 'כן' : 'לא'} */}
                            </span>
                        )}
                    </GameType>
                )}
                {/* <Code data={hiddenValues} /> */}

            </>}

        </div>
    )
}
