import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { gamesContext } from '../contextes/gamesContext';
import { extendGame } from '../functions/extendGame';
import useUser from '../hooks/useUser';
import Bordered from '../layout/Bordered';
import FullGame from './FullGame';


const GamesListOl = styled.ol`
    /* display: grid; */
    /* list-style:none; */
    margin:0 0 2em;
    /* padding:0; */
    li {
        &::marker {
            color: ${props => props.theme.main};
            ${props => props.theme.font.black.normal}
        }
        > a {
            display: inline-block;
            padding: 0.1em 0;
            text-decoration:none;
            color: inherit;
            &:hover {
                color: ${props => props.theme.main};
                ${props => props.theme.font.bold.normal}
            }
        }
        &.extended {
            > a {
                opacity:0.2;
                color: ${props => props.theme.main};
                ${props => props.theme.font.bold.normal}
            }
        }
        .extend {
            margin-inline-start: -1.2em;
            position: relative;
            margin-bottom: 0.5em;
            .close {
                font-size:1.7em;
                color: ${props => props.theme.main};
                position:absolute;
                z-index: 1;
                left:0;
                top:0;
                padding:0.5em;
                display: flex;
                align-items:center;
                justify-content: center;
                cursor: pointer;
                span {
                    font-size:0.7em;
                    padding:0.1em 0.3em 0 0;

                }
                &:hover {
                    opacity: 0.9;
                    color: ${props => props.theme.text};
                }
            }
        }
    }
`;


const LikeCounter = styled.span`
    display:inline-flex;
    margin-inline-start: 0.6em;
    align-items: center;
    font-size:0.85em;
    color: ${props => props.theme.main};
    i {
        font-size:0.85em;
        margin-bottom: 0.2em;
    }
    span {
        ${props => props.theme.font.bold.semiCondensed}
        margin-inline-start: 0.2em;
        
    }
`

function GameListItem({ game }) {
    const [extend, setExtend] = useState(false);
    function toggleExtend(e) {
        e.preventDefault();
        setExtend(!extend);
    }


    // eslint-disable-next-line no-unused-vars
    const [games, setGames, gameTypes] = useContext(gamesContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => extend && extendGame(game, games, setGames), [game, setGames, extend]);




    useFirestoreConnect(() => [
        { collection: 'liked', doc: 'games' }
    ])
    const likedGames = useSelector(
        ({ firestore: { data } }) => data?.['liked']?.['games']
    );

    const user = useUser();

    function userLikedGameId(gameid) {
        return likedGames?.[game.id].includes(user?.data?.id)
    }

    return (
        <li className={extend ? 'extended' : ''}>
            <Link to={`/game/${game.id}`} onClick={toggleExtend}>
                {game.name}

                {likedGames?.[game.id]?.length &&
                    <LikeCounter>
                        {userLikedGameId(game.id) ? <i className="fas fa-heart"></i> : <i className="far fa-heart"></i>}
                        <span>{likedGames?.[game.id]?.length}</span>
                    </LikeCounter>
                }
            </Link>
            {extend &&
                <Bordered className="extend">
                    <div className="close" onClick={() => setExtend(false)}>
                        <i className="fas fa-times-circle"></i>
                    </div>
                    <FullGame game={game} closeFunc={() => setExtend(false)} />
                </Bordered>
            }
        </li>
    )
}


export default function GamesList({ games }) {
    return (
        <GamesListOl>
            {games.map(((game, key) =>
                <GameListItem {...{ game, key }} />
            ))}
        </GamesListOl>
    )
}
