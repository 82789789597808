import { useContext, useEffect } from 'react'
import axios from 'axios';
import { HOME_URL } from '../config';

import isValidEmail from 'is-valid-email';
import { userContext } from '../contextes/userContext';


export default function useUser() {

    const [user, setUser, userEmail, setUserEmail] = useContext(userContext);

    // console.log('useUser userEmail', userEmail);

    function logOut() {
        setUserEmail('');
        setUser({
            email: '',
            isLoggedIn: false,
            loginWithEmail,
            logOut,
            data: null
        });
    }

    async function loginWithEmail(email) {

        setUserEmail(email)
        setUser(null);
        let userRes;

        if (!isValidEmail(email)) {
            console.warn('email is not valid', email);
        } else {
            console.log('valid email!', email)
            userRes = await axios.get(`${HOME_URL}/api/users/email/${email}`);
            console.log('userRes.data', userRes?.data);
        }
        setUser({
            email,
            isLoggedIn: !!userRes?.data.id,
            loginWithEmail,
            logOut,
            data: userRes?.data ? userRes.data : null,
            // hasLoaded: true,
        });



    }


    useEffect(() => {
        if (user?.email === userEmail) {
            // logged in, do nothing
        } else {
            console.log('user.email', user?.email);
            console.log('userEmail', userEmail);
            loginWithEmail(userEmail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail])

    // useEffect(() => {
    //     console.log('user', user);
    // }, [user])


    return user;
}
