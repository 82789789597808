import React from 'react';

import styled, { keyframes } from 'styled-components'

const grow = keyframes`
  0% {
    transform: scale(0);
    opacity:0;
  }   
  30% {
    transform: scale(0.5);
    opacity:1;
  }
  70% {
    transform: scale(1);
    opacity:1;
  }

  100% {
    transform: scale(1.25);
    opacity:0;
  }
`;

const SpinnerWrap = styled.div`
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 93vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select:none;
`;

const Flower = styled.img`
  display: inline-block;
  animation: ${grow} 1.3s linear infinite;
  transform-origin: 50% 80%;
  padding: 2rem 1rem;
  font-size: 1.2rem;
`;

const Text = styled.div`
  font-size:1.3em;
  ${props => props.theme.font.regular.semiCondensed}
  /* color: ${props => props.theme.main}; */
`;

function LoadingSpinner(props) {
  return (
    <SpinnerWrap>
      <Flower src={`${process.env.PUBLIC_URL}/images/flower.png`} alt="רקפת" />
      <Text>
        {props.children}  
      </Text>
    </SpinnerWrap>
  );
}
export default LoadingSpinner;