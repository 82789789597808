import React from 'react'
import styled from 'styled-components';
import useUser from '../hooks/useUser';
import Button from './Button';

const Tag = styled.div`
    margin-bottom:1em;
    h2 {
        margin-bottom: 0.3em;
    }
`;

export default function Hello() {

    const user = useUser();
    return (
        <Tag>
            <h2 className="page-title">{user.data?.firstName ? `היי ${user.data.firstName}! 🙂` : 'היי לך!'}</h2>
            <Button className="hollow small" to="#" onClick={(e) => { e.preventDefault(); user.logOut() }}>זה לא אני</Button>
        </Tag>
    )
}
