
const types = {
    category: 'קטגוריה', //קטגורית משחקים
    skills: 'מיומנויות',
    level: 'רמת קושי',
    groupSize: 'גודל קבוצה',
    compatability: 'מרחב',
    activityType: 'סוגי פעילויות',
};


export function gameTypesFromGames(games) {

    if (!games) return [];
    const __ = []
    Object.keys(types).forEach(type => {
        // console.log('type', type);
        const typeValues = [].concat(...games.map(game => game[type]));
        // console.log('typeValues', typeValues);
        const uniqueTypeValues = [...new Set([...typeValues])];
        const orderedTypeValues = uniqueTypeValues
            .sort((a, b) => (a === '') ? -1 : 1) // space is last
            ;
        // console.log('uniqueTypeValues', uniqueTypeValues);
        // console.log('orderedTypeValues', orderedTypeValues);
        __.push({
            id: type,
            name: types[type],
            values: orderedTypeValues
        });
    });
    console.log('gameTypes', __);
    return __;
}

