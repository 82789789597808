import React from "react";

// eslint-disable-next-line no-unused-vars
import Code from "../../components/Code";
import Button from "../../layout/Button";

import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Wrap from "../../layout/Wrap";
import MainContent from "../../layout/MainContent";
import Header from "../../layout/Header";
import Container from "../../layout/Container";
import styled from "styled-components";
import moment from "moment";
import useUser from "../../hooks/useUser";
import Loading from "../../components/Loading";

function FormatButton({ format }) {
  const user = useUser();
  return (
    <Button
      to={`/format/${format?.id}`}
      className={`fullwidth ${
        user?.data && format?.createdByUser?.id === user?.data?.id ? "" : "hollow"
      }`}
    >
      <span className="name">
        {format?.group?.groupName || "[קבוצה לא נבחרה]"}
      </span>
      {/* <span className="date">נוצר ב־{moment(format?.createdAt?.seconds * 1000).format("DD/MM/YYYY  hh:mm")}</span> */}
      <span className="date">
        {format?.createdByUser?.firstName &&
          format?.createdByUser?.firstName + " "}
        {user?.data && format?.createdByUser?.id === user?.data?.id
          ? "יצרת"
          : format?.createdByUser?.gender === "בן"
          ? "יצר"
          : format?.createdByUser?.gender === "בת"
          ? "יצרה"
          : "נוצר"}
        {/* {format?.createdByUser?.id === user?.data?.id ? "יצרת" : "נוצר"} */}{" "}
        ב־
        {moment(format?.createdAt?.seconds * 1000).format("DD/MM/YYYY  hh:mm")}
      </span>
      {/* <span className="date">נוצר ב־{format.createdAt?.niceText}</span> */}
    </Button>
  );
}

const FormatButtonWrap = styled.div`
  margin-bottom: 0.5em;
`;

export default function Formats() {
  useFirestoreConnect(() => [
    { collection: "formats", orderBy: ["createdAt", "desc"] }, // or `todos/${props.todoId}`
  ]);
  const formats = useSelector(
    ({ firestore: { data } }) => data.formats && Object.values(data.formats)
  );
  console.log("formats", formats);

  return (
    <Container>
      <Header />
      <MainContent>
        <Wrap>
          <h2 className="page-title">מערכים שמורים</h2>
          {!formats ? (
            <Loading>טוען מערכים</Loading>
          ) : (
            formats.map((format, key) => (
              <FormatButtonWrap key={key}>
                {/* <Code data={format} /> */}
                <FormatButton format={format} />
              </FormatButtonWrap>
            ))
          )}
        </Wrap>
      </MainContent>
    </Container>
  );
}
