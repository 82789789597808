import axios from "axios";
import { HOME_URL } from "../config";

export function extendGame(game, games, setGames) {
    if (game?.id) {
        const gameId = game.id;

        const gameIndex = games.findIndex(game => game.id === gameId);
        if (gameIndex) {
            if (!games[gameIndex]?.fetched) {
                console.log('Finding game data...', gameId);
                axios.get(`${HOME_URL}/api/games/${gameId}`)
                    .then(res => {
                        console.log('Found game data for:', gameId);
                        const gameData = res.data;
                        const updatedGames = [
                            ...games.map(game => {
                                if (game.id === gameId) {
                                    return {
                                        ...game,
                                        ...gameData,
                                        fetched: true
                                    }
                                }
                                return game;
                            })
                        ];
                        setGames(updatedGames);
                    }).catch(err => {
                        console.error(err);
                        alert(`שגיאה, פנו למפתח`);
                    });
            }
        }
    }
}