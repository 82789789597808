import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { Redirect, useLocation } from "react-router";
import styled from "styled-components";
import { HOME_URL } from "../config";
import useUser from "../hooks/useUser";

const LikeButton = styled.div`
  cursor: pointer;
`;
const LikeGameWrap = styled.div`
  display: flex;
  align-items: center;
  &.small {
    display: inline-block;
    text-align: center;
    line-height: 1;
    .likes-amount {
      display: block;
      cursor: pointer;
    }
  }
  .like-button {
    i {
      font-size: 1.5em;
      color: ${(props) => props.theme.main};
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
export default function LikeGame({ game, small }) {
  const firestore = useFirestore();

  const user = useUser();
  const location = useLocation();

  useFirestoreConnect(() => [
    { collection: "liked", doc: "games" }, // or `todos/${props.todoId}`
  ]);
  const likedUserIds = useSelector(
    ({ firestore: { data } }) => data?.["liked"]?.["games"]?.[game?.id]
  );
  // console.log('liked', likedUserIds)

  const [likedByThisUser, setLikedByUser] = useState(false);
  useEffect(() => {
    if (likedUserIds) setLikedByUser(likedUserIds.includes(user?.data?.id));
  }, [likedUserIds, user]);

  const [triedToLike, setTriedToLike] = useState(false);

  const [likedUsers, setLikedUsers] = useState();

  useEffect(() => {
    if (!likedUserIds) {
      // console.log('likedUserIds', likedUserIds);
      return;
    }
    const fetch = async () => {
      if (!likedUserIds.length) return;
      const userRes = await axios.get(
        `${HOME_URL}/api/users/public/${likedUserIds.join(",")}`
      );
      // console.log('userRes.data', userRes?.data);
      if (userRes && userRes?.data && userRes?.data?.length)
        setLikedUsers(
          (userRes?.data).filter((_user) => _user.id !== user?.data?.id)
        );
      return userRes;
    };
    return fetch();
  }, [likedUserIds, user]);

  if (triedToLike && !user?.isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: location.pathname },
        }}
      />
    );
  }

  function handleLike(e) {
    setTriedToLike(true);
    setLikedByUser(!likedByThisUser);
    if (!user?.isLoggedIn) return;

    const properties = {
      [game.id]: likedByThisUser
        ? firestore.FieldValue.arrayRemove(user?.data?.id)
        : firestore.FieldValue.arrayUnion(user?.data?.id),
    };

    // console.log('properties', properties);

    firestore
      .collection("liked")
      .doc("games")
      .set(properties, { merge: true })
      .then((ref) => {
        console.log("liked game", properties);
      });
  }

  return (
    <LikeGameWrap className={`like-game ${small ? 'small' : ''}`}>
      <LikeButton onClick={handleLike} className="like-button">
        {likedByThisUser ? (
          <i className="fas fa-heart"></i>
        ) : (
          <i className="far fa-heart"></i>
        )}
      </LikeButton>
      <span className="likes-amount" onClick={small ? handleLike : () => {}}>
        <strong>
          {likedUsers?.length + (likedByThisUser ? 1 : 0) ||
            likedUserIds?.length ||
            0}
        </strong>
      </span>
      {(likedUsers?.length || likedByThisUser) && !small && (
        <span className="who-liked">
          (
          {!likedUsers?.length && likedByThisUser
            ? "אהבת את המשחק"
            : likedUsers.length === 1 && !likedByThisUser
            ? likedUsers[0].gender === "בן"
              ? `${likedUsers[0].firstName} אהב את המשחק`
              : likedUsers[0].gender === "בת"
              ? `${likedUsers[0].firstName} אהבה את המשחק`
              : "מישהו אחד אהב את המשחק"
            : likedByThisUser
            ? (user?.data?.gender === "בן"
                ? "אתה"
                : user?.data?.gender === "בת"
                ? "את"
                : "את/ה") +
              ([...likedUsers].slice(0, -1).length ? ", " : " ") +
              [...likedUsers]
                .slice(0, -1)
                .map((likedUser) => likedUser.firstName + "")
                .join(", ") +
              " ו" +
              (likedUsers?.[likedUsers?.length - 1]?.firstName || "") +
              (user?.data?.gender === "בת" &&
              !likedUsers.find((likedUser) => likedUser.gender !== "בת")
                ? " אהבתן את המשחק"
                : " אהבתם את המשחק")
            : [...likedUsers]
                .slice(0, -1)
                .map((likedUser) => likedUser.firstName + "")
                .join(", ") +
              " ו" +
              (likedUsers?.[likedUsers?.length - 1]?.firstName || "") +
              " אהבו את המשחק"}
          )
        </span>
      )}
    </LikeGameWrap>
  );
}
