

export function retrieveLocalStorage(item, data, set) {
    if (localStorage.getItem(item)) {
        try {
            if (localStorage.getItem(item) === 'null')
                localStorage.setItem(item, JSON.stringify(data))

            set(JSON.parse(localStorage.getItem(item)));
        } catch (e) {
        }
    }
}
export function updateLocalStorage(item, data, set) {
    localStorage.setItem(item, JSON.stringify(data));
}