import React, { } from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border-radius:4em;
    text-align:center;
    position:relative;
    margin-inline-end: 0.7em;
    margin-block-end: 0.7em;
    border:0;
    font-size:0.85em;
    padding:0.5em 0.85em 0.4em;
    box-shadow: 0 0 0 1.3px inset ${props => props.theme.main};
    color: ${props => props.theme.main};
    background: ${props => props.theme.offset};
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.hover};
    }
    .check {
        border-radius:100%;
        width:1.8em;
        height:1.8em;
        border: 1.5px solid ${props => props.theme.offset};
        color: ${props => props.theme.offset};
        background: ${props => props.theme.main};
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        right:-0.5em;
        bottom:-0.7em;
        font-size:0.65em;
        /* transform: translateY(-0em) translateX(2em) scale(0) rotate(-90deg); */
        transform: translateY(0.5em) translateX(0em) scale(0) rotate(-90deg);
        transform-origin: 20% 20%;
    }
    &.checked {
        /* transition: all 200ms ease-out; */
        color: ${props => props.theme.offset};
        background: ${props => props.theme.main};
        .check {
            transition: transform 200ms ease-out;
            transform: translateY(0em) translateX(0em) scale(1) rotate(0deg);
        }
        &:hover {
            opacity: 0.9;
        }
    }
`;

export default function CheckButton(props) {

    // const [checked, setChecked] = useState(true);

    // function toggle() {
    //     setChecked(!checked);
    // }
    return (
        <Button {...props} className={props.checked === undefined ? 'checked' : props.checked ? 'checked' : ''}>
            <span>{props.children}</span>
            <div className="check">
                <i className="fas fa-check"></i>
            </div>
        </Button >
    )
}
