
import React from 'react'
import styled from 'styled-components';

const SmallNotice = styled.div`
    background: ${props => props.theme.hover};
    padding:1em 1.3em;
    border-radius:1em;
    font-size:0.85em;
    margin: 1em 0;
    color: #8a366f;
    p:last-child {
        margin-bottom: 0;
    }
`;


export default function Bubble({ children }) {
    return <SmallNotice>{children}</SmallNotice>
}
