import React, { useState } from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import Code from '../components/Code'
import useUser from '../hooks/useUser'
import Button from '../layout/Button'
import Container from '../layout/Container'
import Header from '../layout/Header'
import MainContent from '../layout/MainContent'
import Wrap from '../layout/Wrap'
import Bubble from '../layout/Bubble'
import { Redirect } from 'react-router-dom';
import { IS_DEV } from '../config'
import Hello from '../layout/Hello'
import Loading from '../components/Loading'


const Form = styled.div`
    max-width: 25em;
    margin: 0 auto;
    .login {

    }
    form {
        display:flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .text {
        margin:1.5em 0;
    }
    h3 {
        margin:0;
    }
    label {
        ${props => props.theme.font.bold.normal}
        margin-inline-end: 0.6em;
        cursor: pointer;
        margin-bottom: 0.3em;
        display: block;
        font-size: 0.8em;
    }
    input {
        flex-grow: 1;
    }
    a {
        margin-inline-start: 0.3em;
    }
`;



export default function Login(props) {

    const [input, setInput] = useState('');

    const user = useUser();

    function login() {
        user.loginWithEmail(input);
    }

    const referrer = props?.location?.state?.referrer;

    if (user?.isLoggedIn) {
        if (referrer) {
            return <Redirect to={referrer} />
        } else {
            // return <Redirect to='/' />
        }
    }

    return (

        <Container>
            <Header />
            <MainContent>
                <Wrap>

                    {(user && user.isLoggedIn) ? <div style={{ textAlign: 'center' }}>
                        <Hello />
                        <Button to="/" className="big">
                            {
                                user.data.gender === 'בן' ?
                                    'בוא נעשה דברים' :
                                    user.data.gender === 'בת' ?
                                        'בואי נעשה דברים' :
                                        'בואו נעשה דברים'
                            }
                        </Button>
                    </div> : <>
                        <h2 className="page-title">מי בדלת?</h2>


                        <Form>

                            {!user ?
                                <Loading>מנסים להכניס אותך</Loading>
                                :
                                <div className="login">
                                    <div className="text">
                                        <p>היי! כדי לעשות את הפעולה הזו צריך להיכנס.</p>
                                        <p>הכניסה באמצעות האימייל שסיפקת לעמותה בהצטרפותך כמנחה.</p>
                                    </div>
                                    <label htmlFor="email">מה האימייל שסיפקת לעמותה?</label>
                                    <form className="form" action="#" onSubmit={login}>
                                        <input placeholder="" type="email" id="email" value={input} onChange={e => setInput(e.target.value)} />
                                        <Button to="#" onClick={e => { e.preventDefault(); login() }} className="button">כניסה</Button>
                                    </form>
                                    {user?.data?.error &&
                                        <Bubble>
                                            <p>
                                                <strong>אין אימייל כזה במערכת של רקפת.
                                                    <br />
                                                    רוצה לבדוק אימייל אחר שלך?</strong>
                                            </p>
                                            <p>אם לדעתך זו טעות, יש לפנות לרכז/ת שלך.</p>
                                        </Bubble>
                                    }
                                </div>
                            }
                        </Form>

                    </>}

                    {IS_DEV && <>
                        <Code data={user} />
                        <Code data={referrer} />
                    </>}

                </Wrap>
            </MainContent>
        </Container>
    )
}
