import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route } from 'react-router-dom';

import useUser from './useUser';
import LoadingSpinner from '../components/LoadingSpinner';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const user = useUser();

    console.log('rest', rest);

    return (
        <Route
            {...rest}
            render={props =>
                !user ? (
                    <LoadingSpinner />
                ) : user.isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: "/login",
                        state: { referrer: rest.location.pathname }
                      }}  />
                )
            }
        />
    );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
    component: PropTypes.any
};