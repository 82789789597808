import axios from "axios";
import { HOME_URL } from "../config";

export function fetchGames({ setGames }) {
    axios.get(`${HOME_URL}/api/games`)
        .then(res => {
            const games = res.data;
            // setGames(_games.slice(0, 10)); // keep only 10 games when developing
            const sortedGames = games.sort((gameA, gameB) => (gameA.name > gameB.name) ? 1 : -1);
            setGames(sortedGames);
        }).catch(err => {
            console.error(err);
            alert(`שגיאה, פנו למפתח:
                
                ${err}`);
        });
}