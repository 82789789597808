import { useEffect } from 'react'
import { retrieveLocalStorage, updateLocalStorage } from '../functions/localStorage.functions';

export default function useLocalStorage(localStorageItem, data, setData) {


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => retrieveLocalStorage(localStorageItem, data, setData), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => updateLocalStorage(localStorageItem, data), [data]);

    return;
}
