import React from 'react'
import styled from 'styled-components';

const BorderedTag = styled.div`
    width: 100%;
    margin: auto;
    max-width:50em;
    margin-bottom: 1.5em;
    border: 1px solid ${props => props.theme.main};
    background: ${props => props.theme.offset};
    padding: 1.5em;
    border-radius: 1em;
`;

export default function Bordered(props) {
    return (
        <BorderedTag {...props}>
            {props.children}
        </BorderedTag>
    )
}
