import React from 'react'
import styled from 'styled-components';


const PopupWrap = styled.div`
    > .popup-background {
        position: fixed;
        left:0;
        right:0;
        bottom:0;
        top:0;
        background: ${props => props.theme.background};
        opacity:0.8;
        z-index: 98;
    }
    > .popup-content {
        position: fixed;
        z-index: 99;
        left:0;
        right:0;
        bottom:0;
        top:0;
        display:inline-flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2em;
        overflow: auto;
        > .inside {
            position:relative;
            width: 100%;
            margin: auto;
            max-width:50em;
            /* margin:1.5em; */
            border: 1px solid ${props => props.theme.main};
            background: ${props => props.theme.offset};
            padding: 1.5em;
            border-radius: 1em;
            h3 {
                text-align: center;
            }
            hr {
                margin-bottom: 1.5em;
            }
            .close {
                font-size:1.7em;
                color: ${props => props.theme.main};
                position:absolute;
                right:0;
                top:0;
                padding:0.5em;
                display: flex;
                align-items:center;
                justify-content: center;
                cursor: pointer;
                span {
                    font-size:0.7em;
                    padding:0.1em 0.3em 0 0;

                }
                &:hover {
                    opacity: 0.9;
                    color: ${props => props.theme.text};
                }
            }
        }
    }
`;


export default function Popup({ status, children }) {

    const [visible, setVisible] = status;

    return visible && <PopupWrap>
        <div className="popup-background"></div>
        <div className="popup-content">
            <div className="inside">
                <div className="close" onClick={() => setVisible(false)}>
                    <i className="fas fa-times-circle"></i>
                    {/* <span>סגור</span> */}
                </div>
                {children}
            </div>
        </div>
    </PopupWrap>;
}