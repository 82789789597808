import React, { useEffect, useMemo, useState } from 'react';
import { Suspense } from 'react';
import { animated, useTransition } from 'react-spring';

import {
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";


import LoadingSpinner from './components/LoadingSpinner';
import { gamesContext } from './contextes/gamesContext';
import { userContext } from './contextes/userContext';
import RandomGame from './pages/RandomGame';
import { gameTypesFromGames } from './functions/gameTypesFromGames';
import { fetchGames } from './functions/fetchGames';
import { routingTransitionConfig } from './functions/routingTransitionConfig';


import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import Formats from './pages/Format/Formats';
import Login from './pages/Login';
import { IS_DEV } from './config';
import PrivateRoute from './hooks/PrivateRoute';
import useLocalStorage from './hooks/useLocalStorage';
import Liked from './pages/Liked';
import Dashboard from './pages/Dashboard';


////////////////// FIREBASE SETUP

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOdi6LlM5nwQOtVaRs9bTyFHiRJ-rgiik",
  authDomain: "rakefet-games.firebaseapp.com",
  projectId: "rakefet-games",
  storageBucket: "rakefet-games.appspot.com",
  messagingSenderId: "70181275979",
  appId: "1:70181275979:web:c351a7c296a00663ea00ce",
  measurementId: "G-WNMQ8KE2G3"
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)


// Initialize other services on firebase instance
const db = firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable


// Emulators
if (IS_DEV) {
  db.useEmulator("localhost", 8080);
}


// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}





////////////////// ROUTING

const Home = React.lazy(() => import('./pages/Home'));
const Format = React.lazy(() => import('./pages/Format/Format'));
const Game = React.lazy(() => import('./pages/Game'));
const Games = React.lazy(() => import('./pages/Games'));
const CreateFormat = React.lazy(() => import('./pages/Format/CreateFormat'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Feedback = React.lazy(() => import('./pages/Feedback'));
const Feedbacks = React.lazy(() => import('./pages/Feedbacks'));





// const requireLogin2 = (to, from, next) => {
//     console.log('to', to);
//     console.log('from', from);
//     console.log('next', next);
//   // if (to.meta.auth) {
//   //   if (getIsLoggedIn()) {
//   //     next();
//   //   }
//   //   next.redirect('/login');
//   // } else {
//   //   next();
//   // }
// };

function App() {


  // gamesContext
  const [games, setGames] = useState([]);
  useEffect(() => fetchGames({ setGames }), []);
  // console.log('games amount', games.length);
  const gameTypes = useMemo(() => gameTypesFromGames(games), [games]);


  // Routing Animations
  const location = useLocation();
  const transitions = useTransition(location, routingTransitionConfig);

  const [user, setUser] = useState(null)
  const [userEmail, setUserEmail] = useState('')
  useLocalStorage('user-email', userEmail, setUserEmail); // do this once when app loads



  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <userContext.Provider value={[user, setUser, userEmail, setUserEmail]}>
          <gamesContext.Provider value={[games, setGames, gameTypes]}>
            <Suspense fallback={<LoadingSpinner />}>
              {transitions((props, item) =>
                <Suspense fallback={<LoadingSpinner location={item} />}>
                  <animated.div style={props}>
                    <Switch location={item}>

                      <PrivateRoute path="/formats" component={Formats} />
                      <Route exact path="/format/"><Redirect to="/formats" /></Route>
                      <PrivateRoute path="/format/:formatId" component={Format} />
                      <PrivateRoute path="/create-format/" component={CreateFormat} />

                      <Route path="/games" component={Games} />
                      <Route exact path="/game/"><Redirect to="/games" /></Route>
                      <Route path="/g/:gameId" render={props => (
                        <Redirect to={`/game/${props.match.params.gameId}`} />
                      )}>
                      </Route>
                      <Route path="/game/:gameId" component={Game} />
                      <Route path="/random-game" component={RandomGame} />

                      <PrivateRoute path="/liked" component={Liked} />

                      <PrivateRoute path="/feedback" component={Feedback} />
                      <PrivateRoute path="/feedbacks" component={Feedbacks} />

                      <PrivateRoute path="/dashboard" component={Dashboard} />

                      <Route path="/login" component={Login} />
                      <Route exact path="/" component={Home} />
                      <Route path="*" component={NotFound} />

                    </Switch>
                  </animated.div>
                </Suspense>
              )}
            </Suspense>
          </gamesContext.Provider>
        </userContext.Provider>
      </ReactReduxFirebaseProvider>
    </Provider >
  );
}

export default App;
