import React from 'react'
import styled from 'styled-components';

const Wrap = styled.div`
padding: 2em 1.5em 2em;
/* min-height: calc( max( 40vh, 18vw) ); */
`;

export default function MainContent(props) {
    return (
        <Wrap>
            {props.children}
        </Wrap>
    )
}
