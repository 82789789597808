import React from "react";
import styled from "styled-components";
import { URL_OF_GAME_ID } from "../config";
// eslint-disable-next-line no-unused-vars
import LikeGame from "./LikeGame";

const LikedWrap = styled.div`
  margin-top: 1em;
  .footer {
    display: flex;
    align-items: center;
    span {
      margin-inline-start: 0.5em;

      ${(props) => props.theme.font.light.normal}
      strong {
        ${(props) => props.theme.font.bold.normal}
      }
    }
  }
`;
const ShareButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: ${(props) => props.theme.main};
  font-size: 1.5em;
  line-height: 1;
  padding: 0 0.4em;
  margin-inline-start: -0.4em;
  &:hover {
    opacity: 0.8;
  }
`;

export default function GameFooter({ game }) {
  function handleWhatsapp() {
    console.log("game", game);
    const text = `*${game.name}*

${game.description} 

${URL_OF_GAME_ID(game.id)}`;
    console.log("text", text);

    window.open(`https://wa.me?text=${encodeURIComponent(text)}`);
  }

  return (
    <LikedWrap>
      <hr />
      <div className="footer">
        <ShareButton onClick={handleWhatsapp}>
          {<i className="fab fa-whatsapp"></i>}
        </ShareButton>
        <LikeGame game={game} />
      </div>
      {/* <Code data={(likedUsers?.[likedUsers?.length - 1])} /> */}
      {/* <Code data={likedUsers} /> */}
    </LikedWrap>
  );
}
