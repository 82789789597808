import React from "react";

import { animated, useSpring } from "react-spring";

export default function AnimatePop({ children }) {
  const anim = useSpring({
    opacity: 1,
    transform: "scale(1)",
    from: { opacity: 0, transform: "scale(0.95)" },
  });
  return <animated.div style={anim}>{children}</animated.div>;
}
