import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  background-color: ${(props) => props.theme.main};
  border-radius: 0.5rem;
  border: none;
  color: ${(props) => props.theme.offset};
  padding: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 0 0.1em;
  position: relative;

  &.big {
    font-variation-settings: "wght" 600, "wdth" 100;
    letter-spacing: 0.03em;
    padding: 0.4em 1em 0.3em;
    font-size: 1.7em;
  }
  &.medium {
    font-variation-settings: "wght" 560, "wdth" 210;
    /* letter-spacing: 0.03em; */
    padding: 0.4em 1em 0.3em;
    font-size: 1.3em;
  }

  &.fullwidth {
    margin-bottom: 0.3em;
    width: 100%;
    display: block;
  }
  &.disabled,
  &[disabled] {
    color: ${(props) => props.theme.text};
    background: ${(props) => props.theme.disabled};
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      opacity: 0.4 !important;
    }
  }
  &.small {
    font-size: 0.85em;
  }
  &.wide {
    min-width: 15em;
    padding: 0.6em 0;
  }

  &:hover:not(.disabled) {
    opacity: 0.9;
  }
  &.hollow {
    background: inherit;
    color: ${(props) => props.theme.main};
    box-shadow: ${(props) => props.theme.main} 0 0 0 1px inset;
    .name {
      color: black;
    }
  }
  i {
    font-size: 0.85em;
    margin-inline-end: 0.1em;
  }
  .name {
    display: block;
    font-variation-settings: "wght" 600, "wdth" 100;
    letter-spacing: 0.03em;
    line-height: 0.4;
    padding: 0.4em 1em 0.3em;
    font-size: 1.5em;
  }
  .date {
    font-size: 0.85em;
    font-variation-settings: "wght" 500, "wdth" 500;
  }
  &.very-small {
    font-size: 0.75em;
    margin-bottom: 0.3em;
    border-radius: 10em;
  }
  &.icon {
    display: inline-flex;
    padding: 0.7em 1.2em 0.5em 1.5em;
    font-size: 0.8em;
    letter-spacing: 0;
    font-variation-settings: "wght" 700, "wdth" 500;
    border-radius: 10em;
    align-items: center;
    i {
      font-size: 2em;
      margin-top: -0.1em;
    }
    div {
      margin-inline-start: 0.4em;
      text-align: start;
    }
  }
  &.rounded {
    border-radius: 10em;
  }
  &.approve {
    display: inline-flex;
    border-radius: 10em;
    line-height: 0.9;
    padding: 0.4em 0.7em 0.4em 0.8em;
    align-items: center;
    justify-content: center;
    div.inside > div {
      margin-top: 0.1em;
      text-align: start;
      display: inline-flex;
      i {
        margin-top: -0.1em;
        margin-bottom: auto;
      }
      > span {
        margin-inline-start: 0.15em;
      }
    }
  }
  span.new {
    font-variation-settings: "wght" 700, "wdth" 300;
    font-size: 0.55em;
    position: absolute;
    top: -0.25em;
    left: -0.9em;
    color: white;
    padding: 0.25em 0.4em 0.2em 0.45em;
    line-height: 1em;
    transform: rotate(-12deg);
    box-shadow: 0.1em 0.3em 0 rgba(236, 63, 181, 0.3);
    letter-spacing: 0.05em;
    background: ${(props) => props.theme.main};
    border-radius: 1em;

    animation: rotated-hint 4s linear infinite
      2.5s;
    @keyframes rotated-hint {
      0% {
        transform: rotate(-12deg);
      }
      5% {
        transform: rotate(-20deg);
      }
      10% {
        transform: rotate(-12deg);
      }
      15% {
        transform: rotate(-20deg);
      }
      20% {
        transform: rotate(-12deg);
      }
    }
  }
`;

export default function Button(props) {
  return <StyledLink {...props}>{props.children}</StyledLink>;
}
