import React from 'react'
import styled from 'styled-components';

const ContainerTag = styled.div`
/* min-height: 100vh;
display: flex;
flex-direction: column; */
`;

export default function Container(props) {
    return (
        <ContainerTag>
            {props.children}
        </ContainerTag>
    )
}
