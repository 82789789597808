import React from 'react'
import styled from 'styled-components'

const WrapTag = styled.div`
    max-width: 800px;
    margin:0 auto;
`;

export default function Wrap(props) {
    return (
        <WrapTag>
            {props.children}
        </WrapTag>
    )
}
