import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import GamesList from '../components/GamesList';
import Loading from '../components/Loading';
import { gamesContext } from '../contextes/gamesContext';
import useUser from '../hooks/useUser';
import Button from '../layout/Button';
import Container from '../layout/Container';
import Header from '../layout/Header';
import MainContent from '../layout/MainContent';
import Notice from '../layout/Notice';
import Wrap from '../layout/Wrap'

export default function Liked() {


    useFirestoreConnect(() => [
        { collection: 'liked', doc: 'games' } // or `todos/${props.todoId}`
    ])

    const user = useUser();

    const likedGameIds = useSelector(
        ({ firestore: { data } }) => data['liked'] && Object.keys(data['liked']['games'])
            .filter(game => data['liked']['games'][game].includes(user?.data?.id))
    );

    // console.log('likedGames', likedGameIds);

    // eslint-disable-next-line no-unused-vars
    const [games, setGames, gameTypes] = useContext(gamesContext);

    const likedGames = useMemo(() => likedGameIds && games.filter(game => likedGameIds.includes(game.id)), [games, likedGameIds])

    return (
        <Container>
            <Header />
            <MainContent>
                <Wrap>
                    <h2 className="page-title">משחקים שאהבת</h2>
                    {
                        !likedGames?.length ? <div style={{ textAlign: 'center' }}>
                            <Notice>כאן יופיעו כל המשחקים שסימנת בלב.
                                <br />
                                נראה שעדיין לא עשית לייק לאף משחק
                            </Notice>
                            <Button to="/games">לרשימת המשחקים</Button>
                        </div> :
                            <>
                                {user?.data?.firstName && <Notice>{user?.data?.firstName}, איזה טעם טוב יש לך! 😉</Notice>}
                                <GamesList games={likedGames} />
                            </>
                    }
                    {!user && <Loading></Loading>}
                </Wrap>
            </MainContent>
        </Container>
    )
}
