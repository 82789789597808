import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Container from "../layout/Container";
import Header from "../layout/Header";
import MainContent from "../layout/MainContent";
import Wrap from "../layout/Wrap";
import Code from "../components/Code";
import { groupBy, uniq } from "lodash";
import Loading from "../components/Loading";
import { Chart as ChartJS, registerables } from "chart.js";

import { Line } from "react-chartjs-2";
import { IS_DEV } from "../config";

ChartJS.register(...registerables);

export default function Dashboard() {
  useFirestoreConnect(() => [
    { collection: "formats", orderBy: ["createdAt", "desc"] }, // or `todos/${props.todoId}`
  ]);
  const formats = useSelector(
    ({ firestore: { data } }) => data.formats && Object.values(data.formats)
  );
  console.log("formats", formats);

  const okFormats = useMemo(
    () =>
      (formats &&
        formats.filter(
          (format) =>
            typeof format.games === "object" &&
            Object.values(format.games)?.length >= 4
        )) ||
      [],

    [formats]
  );

  const months = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ];

  const yearGroups = useMemo(() => {
    const yearsObj = groupBy(okFormats, (format) =>
      moment(format?.createdAt?.seconds * 1000).year()
    );
    const years = uniq(Object.keys(yearsObj));

    years.forEach((year) => {
      yearsObj[year] = groupBy(yearsObj[year], (format) =>
        moment(format?.createdAt?.seconds * 1000).month()
      );
    });

    return yearsObj;
  }, [okFormats]);
  console.log({ yearGroups });

  const years = useMemo(
    () => (yearGroups && uniq(Object.keys(yearGroups))) || [],
    [yearGroups]
  );

  const data = {
    labels: months,
    datasets: years.map((year, key) => {
      const isLastYear = key === (years.length - 1);
      return {
        label: year,
        data: months.map(
          (month, monthKey) => yearGroups[year][monthKey]?.length || 0
        ),
        fill: isLastYear,
        backgroundColor: isLastYear ? "#EC3FB544" : "rgba(75,192,192,0.0)",
        borderColor: isLastYear ? "#EC3FB5" : "rgba(75,192,192,1)",
      };
    }),
    // {
    //   label: "First dataset",
    //   data: [33, 53, 85, 41, 44, 65],
    //   fill: true,
    //   // backgroundColor: "rgba(75,192,192,0.2)",
    //   // borderColor: "rgba(75,192,192,1)",
    // },
    // {
    //   label: "Second dataset",
    //   data: [33, 25, 35, 51, 54, 76],
    //   fill: false,
    //   // borderColor: "#742774",
    // },
  };

  return (
    <Container>
      <Header />
      <MainContent>
        <Wrap>
          {!formats ? (
            <Loading>טוען מידע</Loading>
          ) : (
            <div>
              <h2 className="page-title">סטטיסטיקה</h2>
              <h3>כמה מערכים נוצרו עד היום?</h3>
              <p>
                עד היום נוצרו {formats?.length} מערכים, מתוכם{" "}
                {okFormats?.length} מלאים.
              </p>

              <br />
              <h3>כמה מערכים מלאים יצרו בכל חודש?</h3>
              <p>'מערך מלא' מכיל 4 משחקים ומעלה.</p>

              <Line data={data} />

              {IS_DEV && (
                <>
                  <Code data={data} />
                </>
              )}
            </div>
          )}
        </Wrap>
      </MainContent>
    </Container>
  );
}
