import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import Wrap from './Wrap';


const HeaderTag = styled.header`
    border-bottom: 1px solid ${props => props.theme.main};
    > div {
        position: relative;
    }
`;

const Inside = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > a {
        display: inline-flex;
        align-items: center;
    }
`;

const Logo = styled(Link)`
    font-weight: 700;
    font-size:0.8em;
    text-decoration: none;
    color:inherit;
    padding: 0.8em 1em;
`;
const Icon = styled.img`
    margin-left: 1em;
    width: 3.5em;
    padding-bottom: .6em;
    margin-inline-start:-0.3em;
`;
const Name = styled.h1`
    ${props => props.theme.font.light.condensed}
    font-weight:normal;
    font-size:3.3em;
    letter-spacing: -.015em;
    line-height: 0.9;
    margin: 0;
    /* margin-top: .17em; */
`;
const Slogen = styled.h2`
    ${props => props.theme.font.regular.normal}
    font-weight:normal;
    letter-spacing: -.015em;
    font-size:0.99em;
    margin: 0;
    line-height: 0.9;
    `;
const IconButton = styled(Link)`
    width: 1.8em;
    height: 2em;
    background: none;
    font-size: 1.45em;
    padding: 1em 0.3em;
    box-sizing: content-box;
    line-height: 1;
    border: none;
    top: 0em;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    color: ${props => props.theme.main};
    &:hover {
        opacity: 1;
        color: ${props => props.theme.main};
    }
`;


export default function Header() {
    const history = useHistory();
    const location = useLocation();
    return (
        <HeaderTag>
            <Wrap>
                {location.pathname.includes('game') && location.pathname !== '/' && <IconButton to="/" style={{ 'right': 0 }}><i className="fas fa-home"></i></IconButton>}
                {!location.pathname.includes('game') && location.pathname !== '/' && <IconButton to="#" onClick={history.goBack} style={{ 'right': 0 }}><i className="fas fa-arrow-right"></i></IconButton>}
                <Inside>
                    <Logo to="/">
                        <Icon src={`${process.env.PUBLIC_URL}/images/flower.png`} alt="רקפת" />
                        <div>
                            <Name>מה נשחק היום?</Name>
                            <Slogen>רעיונות לפעילות רקפת בקליק</Slogen>
                        </div>
                    </Logo>
                </Inside>
                {location.pathname !== '/liked' && location.pathname !== '/' && <IconButton to="/liked" style={{ 'left': 0 }}><i className="fas fa-heart"></i></IconButton>}
                {location.pathname === '/liked' && location.pathname !== '/' && <IconButton to="/" style={{ 'left': 0 }}><i className="fas fa-home"></i></IconButton>}
            </Wrap>
        </HeaderTag>
    )
}
