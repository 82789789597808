import React from 'react'
import styled from 'styled-components';

const LoadingTag = styled.div`
    text-align: center;
    margin-top:0.5em;
    color: ${props => props.theme.main};
    ${props => props.theme.font.bold.normal}
`;

export default function Loading({children}) {
    return (
        <LoadingTag>
            {children} <i className="fas fa-spinner fa-pulse"></i>
        </LoadingTag>
    )
}
