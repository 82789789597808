import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { gamesContext } from '../contextes/gamesContext'
import Button from '../layout/Button'
import Container from '../layout/Container'
import Header from '../layout/Header'
import MainContent from '../layout/MainContent'
import Wrap from '../layout/Wrap'

import LoadingSpinner from '../components/LoadingSpinner'
import FullGame from '../components/FullGame'
import { gameFilter } from '../functions/gameFilter'
import Popup from '../components/Popup'
// eslint-disable-next-line no-unused-vars
import Code from '../components/Code'
import { getRandomGameId } from '../functions/getRandomGameId'
import { extendGame } from '../functions/extendGame'
import GameSettings from '../components/GameSettings'
import Notice from '../layout/Notice'
import useLocalStorage from '../hooks/useLocalStorage'

const Centered = styled.div`
    text-align:center;
    margin-bottom: 2em;
    a {
        margin-bottom:0.2em;
    }
`;



export default function RandomGame() {

    // eslint-disable-next-line no-unused-vars
    const [games, setGames, gameTypes] = useContext(gamesContext);

    const [hiddenValues, setHiddenValues] = useState({});
    const [editingSettings, setEditingSettings] = useState(false);

    const [currentGameId, setCurrentGameId] = useState()

    function setRandomGame() {
        setCurrentGameId(getRandomGameId({ games: filteredGames }));
    }
    const game = useMemo(() => games ? games.find(game => game.id === currentGameId) : null, [currentGameId, games]);

    const filter = useCallback(game => gameFilter({ game, hiddenValues, gameTypes }), [hiddenValues, gameTypes]);
    const filteredGames = useMemo(() => games.filter(filter), [games, filter])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => extendGame(game, games, setGames), [game, setGames]);


    
    useLocalStorage('random-game-hidden-values', hiddenValues, setHiddenValues);

    return (
        <Container>
            <Header />
            <MainContent>
                <Wrap>

                    <Centered>
                        <h2 className="page-title">רעיון למשחק בקליק</h2>
                        {games.length ?
                            <>
                                <Button to="#" className={`big ${filteredGames?.length ? '' : 'disabled'}`} onClick={(e) => { e.preventDefault(); setRandomGame(hiddenValues) }}><i className="fas fa-dice-three"></i> זרקו רעיון למשחק ({filteredGames?.length ? filteredGames?.length : 0})</Button>
                                <Button to="#" className="big hollow" onClick={(e) => { e.preventDefault(); setEditingSettings(true) }}><i className="fas fa-cog"></i> הגדרות</Button>

                            </>
                            : <LoadingSpinner>טוען משחקים</LoadingSpinner>
                        }
                    </Centered>

                    {<Popup status={[editingSettings, setEditingSettings]}>
                        <GameSettings {...{ hiddenValues, setHiddenValues, filteredGames }} hideToggle={true} />
                        <Button style={{ marginTop: '0.7em' }} to="#" className={`big fullwidth ${filteredGames?.length ? '' : 'disabled'}`} onClick={(e) => { e.preventDefault(); setRandomGame(hiddenValues); setEditingSettings(false) }}><i className="fas fa-dice-three"></i> זרקו רעיון למשחק ({filteredGames?.length ? filteredGames?.length : 0})</Button>
                    </Popup>}

                    {currentGameId === '' && <Notice>לא נמצא משחק לפי ההגדרות שנבחרו, נסו לשנות את ההגדרות.</Notice>}
                    <FullGame game={game} />

                </Wrap>
            </MainContent>
        </Container>
    )
}
